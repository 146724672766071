export const createAndSendForm = (action, params) => {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = action;
  for (let key in params) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = params[key];
    form.appendChild(input);
  }
  document.body.appendChild(form);
  form.submit();
};

const IFRAME_STYLES = {
  position: 'absolute',
  left: '-1000px',
  top: '-1000px',
  height: '1px',
  width: '1px',
  opacity: 0,
};

const createIframeForm = ({ index, url, method, params = {} }) => {
  const inputs = Object.entries(params)
    .map(
      ([name, value]) =>
        `<input type="hidden" name="${name}" value="${value}">`,
    )
    .join('');
  return `<form id="precondition-form-${index}" action="${url}" method="${method}">${inputs}</form>`;
};

export const injectCss = (domElement, cssObj = {}) => {
  Object.entries(cssObj).forEach(
    ([key, value]) => (domElement.style[key] = value),
  );
  return domElement;
};

export const handlePrecondition = ({
  index,
  url,
  method = 'POST',
  params = {},
  stylesObj = IFRAME_STYLES,
}) => {
  return new Promise((resolve, reject) => {
    const iframe = document.createElement('iframe');
    iframe.id = `precondition-iframe-${index}`;
    iframe.src = 'about:blank';
    iframe.frameBorder = '0';
    injectCss(iframe, stylesObj);
    document.querySelector('body').appendChild(iframe);
    iframe.contentDocument.write(
      createIframeForm({ index, url, method, params }),
    );
    iframe.onload = () => {
      resolve();
    };
    iframe.onerror = () => {
      reject();
    };
    iframe.contentWindow.document.querySelector('form').submit();
  });
};

export const createIFrameAndResolveMessage = (src, isHidden) => {
  const iframe = document.createElement('iframe');

  iframe.id = 'payment-iframe';
  iframe.src = src;
  iframe.height = isHidden ? '10' : '';
  iframe.width = isHidden ? '10' : '';
  iframe.style = isHidden
      ? 'display: none'
      : 'width: 100%; height: 100vh; position: fixed; left:0; top:0; z-index: 1000; border:none';

  document.querySelector('body').appendChild(iframe);

  return new Promise(resolve => {
    window.addEventListener(
        'message',
        event => {
          if (event.data.result) {
            resolve(event.data);
            removeIframe('payment-iframe');
          }
        },
        false
    );
  });
};

export const removeIframe = id => {
  const iframeElement = document.getElementById(id);

  iframeElement?.parentNode?.removeChild(iframeElement);
};
